import React, { Fragment, useEffect } from "react"
import { PageProps } from "gatsby"
import { useOrganization } from "src/hooks"

function Privacy(props: PageProps) {
	const organization = useOrganization()

	useEffect(() => {
		if (organization?.privacy_link) {
			window.open(organization.privacy_link, '_blank')
		}
		window.location.href = '/'
	}, [organization?.privacy_link])

	return <Fragment />
}

export default Privacy